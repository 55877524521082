/**
 * Zwraca współrzędne ponktu względem od podanego punktu
 *
 * @param brng kierunek w stopniach
 * @param dist odleglosc w km
 * @param param2 pozycja startowa
 * @returns
 */
export function getPoint(brng: number, dist: number, { lat, lng }: { lat: number, lng: number }) {
    let dist1 = dist / 6371;
    let brng1 = toRad(brng);

    let lat1 = toRad(lat), lon1 = toRad(lng);

    let lat2 = Math.asin(Math.sin(lat1) * Math.cos(dist1) +
        Math.cos(lat1) * Math.sin(dist1) * Math.cos(brng1));

    let lon2 = lon1 + Math.atan2(Math.sin(brng1) * Math.sin(dist1) *
        Math.cos(lat1),
        Math.cos(dist1) - Math.sin(lat1) *
        Math.sin(lat2));

    if (isNaN(lat2) || isNaN(lon2)) return null;

    return {
        lat: toDeg(lat2),
        lng: toDeg(lon2)
    };
}

function toRad(value: number) {
    return value * Math.PI / 180;
}

function toDeg(value: number) {
    return value * 180 / Math.PI;
}

export function bearing(
    { lat: startLat1, lng: startLng1 }: { lat: number, lng: number },
    { lat: destLat1, lng: destLng1 }: { lat: number, lng: number }
) {
    let startLat = toRad(startLat1);
    let startLng = toRad(startLng1);
    let destLat = toRad(destLat1);
    let destLng = toRad(destLng1);

    const y = Math.sin(destLng - startLng) * Math.cos(destLat);
    const x = Math.cos(startLat) * Math.sin(destLat) -
        Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
    let brng1 = Math.atan2(y, x);
    brng1 = toDeg(brng1);
    return (brng1 + 360) % 360;
}
