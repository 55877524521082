import { GameScenario, GAME_STEP_TYPE } from "./types";

export const SCENARIO: GameScenario = {
    startStep: '0.1',
    steps: [
        {
            id: '0.1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-1',
            nextStep: null
        },
        {
            id: 'S09712',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-76', //instr
            nextStep: '1.1'
        },
        {
            id: '1.1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-2',
            nextStep: '1.2'
        },
        {
            id: '1.2',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-3',
            nextStep: '1.3'
        },
        {
            id: '1.3',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-4',
            nextStep: '1.4'
        },
        {
            id: '1.4',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-5',
            nextStep: '1.5'
        },
        {
            id: '1.5',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-6',
            nextStep: '0.1'
        },
        {
            id: 'FY7201',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-63', //instr
            nextStep: '2.1.0'
        },
        {
            id: '2.1.0',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-7',
            nextStep: '2.1'
        },
        {
            id: '2.1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-8',
            nextStep: '2.2'
        },
        {
            id: '2.2',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-9',
            nextStep: '2.3'
        },
        {
            id: '2.3',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-10',
            nextStep: '2.4'
        },
        {
            id: '2.4',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-11',
            nextStep: '0.1'
        },
        {
            id: 'HA601S',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-64',
            nextStep: '3.1.0'
        },
        {
            id: '3.1.0',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-12',
            nextStep: '3.1'
        },
        {
            id: '3.1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-13',
            nextStep: '3.2'
        },
        {
            id: '3.2',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-14',
            nextStep: '3.3'
        },
        {
            id: '3.3',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-15',
            nextStep: '3.4'
        },
        {
            id: '3.4',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-16',
            nextStep: '0.1'
        },
        {
            id: '60SR4L',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-65',
            nextStep: '4.1.0'
        },
        {
            id: '4.1.0',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-17',
            nextStep: '4.1'
        },
        {
            id: '4.1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-18',
            nextStep: '4.2'
        },
        {
            id: '4.2',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-19',
            nextStep: '4.3'
        },
        {
            id: '4.3',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-20',
            nextStep: '4.4'
        },
        {
            id: '4.4',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-21',
            nextStep: '0.1'
        },
        {
            id: 'PAT27B',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-71',
            nextStep: '5.1.0'
        },
        {
            id: '5.1.0',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-22',
            nextStep: '5.1'
        },
        {
            id: '5.1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-23',
            nextStep: '5.2'
        },
        {
            id: '5.2',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-24',
            nextStep: '5.3'
        },
        {
            id: '5.3',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-25',
            nextStep: '5.4'
        },
        {
            id: '5.4',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-26',
            nextStep: '0.1'
        },
        {
            id: 'GZP710',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-73',
            nextStep: '6.1.0'
        },
        {
            id: '6.1.0',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-27',
            nextStep: '6.1'
        },
        {
            id: '6.1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-28',
            nextStep: '6.2'
        },
        {
            id: '6.2',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-29',
            nextStep: '6.3'
        },
        {
            id: '6.3',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-30',
            nextStep: '6.4'
        },
        {
            id: '6.4',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-60',
            nextStep: '0.1'
        },
        //TF

        {
            id: 'NQ1S67',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-84',
            nextStep: '69.1'
        },
        {
            id: '69.1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-31', //ułóż w kolejności
            nextStep: '0.1'
        },
        {
            id: '5S6B2X',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-79',
            nextStep: '7.1'
        },
        {
            id: '7.1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-32', //memory
            nextStep: '0.1'
        },
        {
            id: 'MNW111',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-85',
            nextStep: '8.1'
        },
        {
            id: '8.1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-33', //puzzle
            nextStep: '0.1'
        },
        {
            id: 'PL2S6A',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-87',
            nextStep: '9.1'
        },
        {
            id: '9.1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-34', // znajdź róźnicę
            nextStep: '0.1'
        },
        {
            id: 'STA531',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-81',
            nextStep: '10.1.0'
        },
        {
            id: '10.1.0',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-35', //wpisz hasło 
            nextStep: '10.1'
        },
        {
            id: '10.1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-41',//wpisz hasło 
            nextStep: '10.2'
        },
        {
            id: '10.2',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-42',//wpisz hasło 
            nextStep: '10.3'
        },
        {
            id: '10.3',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-43',//wpisz hasło 
            nextStep: '10.4'
        },
        {
            id: '10.4',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-44',//wpisz hasło 
            nextStep: '0.1'
        },

        {
            id: 'KL24S0',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-88',
            nextStep: '11.1'
        },

        {
            id: '11.1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-36', // linia czasu
            nextStep: '0.1'
        },
        {
            id: '6S8G41',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-86',
            nextStep: '12.1'
        },
        {
            id: '12.1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-37', //zrób zdjęcie
            nextStep: '0.1'
        },
        {
            id: '852VST',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-82',
            nextStep: '13.1'
        },
        {
            id: '13.1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-38',//segregacja
            nextStep: '0.1'
        },
        {
            id: '0012GS',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-83',
            nextStep: '14.1'
        },
        {
            id: '14.1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-39', //krzyżówka
            nextStep: '0.1'
        },
        {
            id: '63G8S1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-80',
            nextStep: '15.1'
        },
        {
            id: '15.1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-40',//wisielec
            nextStep: '15.2'
        },
        {
            id: '15.2',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-55',//wisielec
            nextStep: '15.3'
        },
        {
            id: '15.3',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-56',//wisielec
            nextStep: '15.4'
        },
        {
            id: '15.4',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-57',//wisielec
            nextStep: '15.5'
        },
        {
            id: '15.5',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-58',//wisielec
            nextStep: '0.1'
        },


        {
            id: 'NS6ST3',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-78',
            nextStep: '17.1.0'
        },

        {
            id: '17.1.0',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-50', //dekady
            nextStep: '17.1'
        },
        {
            id: '17.1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-51', //dekady
            nextStep: '17.2'
        },
        {
            id: '17.2',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-52', //dekady
            nextStep: '17.3'
        },
        {
            id: '17.3',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-53', //dekady
            nextStep: '17.4'
        },
        {
            id: '17.4',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-54', //dekady
            nextStep: '0.1'
        },

        //duplikaty tu wlecą


        //ABCD
        {
            id: '23S9A1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-77',
            nextStep: '16.1.0'
        },
        {
            id: '16.1.0',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-45',
            nextStep: '16.1'
        },
        {
            id: '16.1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-46',
            nextStep: '16.2'
        },
        {
            id: '16.2',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-47',
            nextStep: '16.3'
        },
        {
            id: '16.3',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-48',
            nextStep: '16.4'
        },
        {
            id: '16.4',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-49',
            nextStep: '0.1'
        },


        //wpisz hasło 2
        {
            id: '2DS01A',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-72',
            nextStep: '20.1.0'
        },
        {
            id: '20.1.0',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-66',
            nextStep: '20.1'
        },
        {
            id: '20.1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-67',
            nextStep: '20.2'
        },
        {
            id: '20.2',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-68',
            nextStep: '20.3'
        },
        {
            id: '20.3',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-69',
            nextStep: '20.4'
        },
        {
            id: '20.4',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-70',
            nextStep: '0.1'
        },

        {
            id: 'MA5SF1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-61',
            nextStep: '23.1'
        },
        {
            id: '23.1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-59', // hasło reklamowe
            nextStep: '0.1'
        },
        {
            id: 'PSW5L1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-62',
            nextStep: '24.1'
        },
        {
            id: '24.1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-89', // zdjęcie elementu auta
            nextStep: '0.1'
        },
        {
            id: 'SUMMARY',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'summary-page', //podsumowanie
            nextStep: ''
        },

        //bezobsługowe

        {
            id: 'V0LV01',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-74', // instr
            nextStep: '25.1'
        },
        {
            id: '25.1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-75', //połacz w pary
            nextStep: '0.1'
        },
        {
            id: 'HDK23J',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-90', // instr
            nextStep: '26.1'
        },
        {
            id: '26.1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-91', //spalanie
            nextStep: '0.1'
        },
        {
            id: 'VS62N1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-92', // instr
            nextStep: '27.1'
        },
        {
            id: '27.1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-93', //batony
            nextStep: '0.1'
        },
        {
            id: 'VSU371',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-94', // instr
            nextStep: '28.1'
        },
        {
            id: '28.1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-95', //zjeżdżalnia
            nextStep: '0.1'
        },

        //animator

        {
            id: 'XTN373',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-96', // instr
            nextStep: '29.1'
        },
        {
            id: '29.1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-97', //aero
            nextStep: '0.1'
        },
        {
            id: 'VSJUT8',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-98', // instr
            nextStep: '30.1'
        },
        {
            id: '30.1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-99', //gpt
            nextStep: '0.1'
        },
        {
            id: '123451', //
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-100', // instr
            nextStep: '31.1'
        },
        {
            id: '31.1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-101', //kamery
            nextStep: '0.1'
        },
        {
            id: 'X1YV23',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-102', // instr
            nextStep: '32.1'
        },
        {
            id: '32.1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-103', //plac manewrowy
            nextStep: '0.1'
        },
        {
            id: 'W2P43A',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-104', // instr
            nextStep: '33.1'
        },
        {
            id: '33.1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-105', //gps
            nextStep: '0.1'
        },
        {
            id: 'HVJPA2',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-106', // instr
            nextStep: '34.1'
        },
        {
            id: '34.1',
            type: GAME_STEP_TYPE.PAGE,
            pageId: 'page-107', //well being
            nextStep: '0.1'
        },






    ]
}


